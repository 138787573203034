import React, { useCallback, useReducer } from 'react';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonStyle, Header as UIHeader, Icon, IconNames, } from '@sovryn/ui';
import { ConnectWalletButton } from '../../2_molecules';
import { NetworkPicker } from '../../2_molecules/NetworkPicker/NetworkPicker';
import { SovrynLogo } from '../../2_molecules/SovrynLogo/SovrynLogo';
import { useWalletConnect, useWrongNetworkCheck } from '../../../hooks';
import { useCurrentChain } from '../../../hooks/useChainStore';
import { translations } from '../../../locales/i18n';
import { isBobChain } from '../../../utils/chain';
import { getOriginsUrl } from '../../../utils/helpers';
import { menuItemsMapping } from './Header.constants';
import { BridgeMenuItem } from './components/BridgeMenuItem/BridgeMenuItem';
import { NavItem } from './components/NavItem/NavItem';
import { ProductLinks } from './components/ProductLinks/ProductLinks';
export const Header = () => {
    const chainId = useCurrentChain();
    const navigate = useNavigate();
    const [isOpen, toggle] = useReducer(v => !v, false);
    const { connectWallet, disconnectWallet, account, pending } = useWalletConnect();
    useWrongNetworkCheck();
    const handleNavClick = useCallback(() => {
        if (isOpen) {
            toggle();
        }
    }, [isOpen]);
    return (React.createElement(React.Fragment, null,
        React.createElement(UIHeader, { dataAttribute: "dapp-header", logo: React.createElement(SovrynLogo, { dataAttribute: "header-logo", link: "/", onClick: handleNavClick }), isOpen: isOpen, menuIcon: React.createElement(Button, { text: React.createElement(Icon, { icon: isOpen ? IconNames.X_MARK : IconNames.HAMBURGER_MENU }), style: ButtonStyle.ghost, onClick: toggle, className: "text-white" }), menuItems: React.createElement("ol", { className: "flex flex-col gap-4 lg:flex-row w-full lg:w-auto" },
                menuItemsMapping.map((item, index) => (React.createElement("li", { key: index },
                    React.createElement(NavItem, { item: item, onClick: toggle })))),
                React.createElement("li", null,
                    React.createElement("a", { href: getOriginsUrl(), target: "_blank", rel: "noopener noreferrer", className: "text-gray-10 font-normal text-sm text-opacity-75 hover:text-gray-10 no-underline px-2 py-3" }, t(translations.header.nav.origins))),
                React.createElement(ProductLinks, null),
                isBobChain(chainId) && (React.createElement(React.Fragment, null,
                    React.createElement(Button, { text: t(translations.header.nav.runes), style: ButtonStyle.primary, className: "bg-[#24BFB74D]/[0.3] border-[#24BFB74D]/[0.3] hover:bg-[#24BFB74D]", onClick: () => navigate('/runes') }),
                    React.createElement(Button, { text: t(translations.header.nav.spicePoints), style: ButtonStyle.primary, className: "bg-[#24BFB74D]/[0.3] border-[#24BFB74D]/[0.3] hover:bg-[#24BFB74D] lg:invisible xl:visible", onClick: () => navigate('/bob-lp-points') })))), secondaryContent: React.createElement("div", { className: "relative flex flex-row gap-4" },
                React.createElement(NetworkPicker, null),
                React.createElement(ConnectWalletButton, { onConnect: connectWallet, onDisconnect: disconnectWallet, address: account, pending: pending, dataAttribute: "dapp-header-connect" })), extraContent: React.createElement("div", { className: "flex lg:space-x-4 items-center flex-wrap lg:flex-nowrap flex-col-reverse lg:flex-row lg:justify-start" },
                React.createElement("div", { className: "w-full lg:w-auto mt-2 lg:mt-0" },
                    React.createElement(BridgeMenuItem, { dataAttribute: "dapp-header-bridges" }))) })));
};
